import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Assembly - Finish"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Step 8. Finish`}</h1>
    <p>{`Whilst working on a project like this, some of the screws and bolts you have put in may have worked a little loose with the movement generated by assembling the structure. So, please check that all fixings are re-tightened as necessary to ensure that they are secure.`}</p>
    <p><strong parentName="p">{`That’s it! Your Pergola is now up and ready for you to enjoy allowing you to make the most of our warmer, longer summers and increase the enjoyment of an active, outdoor lifestyle.`}</strong></p>
    <img src="/img/pergola-assembly/step-8-finish/aboutus-pic22.jpg" />
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      